import React from 'react'
import { Link } from 'react-router-dom'
import {
  footerEasypaisa,
  footerJazzcash,
  footerBankTransfer,
  footerTwitter,
  footerfacebook,
  footerInstagram,
  footerLinkedIn,
} from '../../assets/landingPageImgs'
import { countryFlag, locationIcon } from '../../assets';
import { LexaLogoHomeIcon } from '../../assets/icons'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
const BlogsFooter = ({ latestBlogs, relatedPosts }) => {
  let blogsToDisplay
  let sectionTitle
  let displayContactInfo = false

  if (latestBlogs && latestBlogs.length > 0) {
    blogsToDisplay = latestBlogs
    sectionTitle = 'Latest Blogs'
  } else if (relatedPosts && relatedPosts.length > 0) {
    blogsToDisplay = relatedPosts
    sectionTitle = 'Related Posts'
  } else {
    displayContactInfo = true // Set to true if no blogs or related posts are available
  }
  return (
    <footer className='bg-cover bg-no-repeat mt-20'>
      {/* // style={{ backgroundImage: `url(${bgImage})` }}> */}
      <div className='mx-auto max-w-screen-xl space-y-8 px-4 pt-16 sm:px-6 lg:space-y-16 lg:px-8'>
        <div className='grid grid-cols-1 gap-8 lg:grid-cols-3'>
          <div>
            <div className='text-teal-600'>
              <LexaLogoHomeIcon />
              <svg
                className='h-8'
                viewBox='0 0 118 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                {/* Path data omitted for brevity */}
              </svg>
            </div>
            <p className='mt-2 max-w-xs text-white'>
              LEXA is a not a product, its a vision that revolutionizes the
              legal landscape by simplifying legal research through AI.
            </p>
            <ul className='mt-8 flex gap-6 pl-0'>
              <li>
                <a
                  href='https://www.facebook.com/profile.php?id=61554795011681'
                  rel='noreferrer'
                  target='_blank'
                  className='text-white transition hover:opacity-75'
                >
                  <img src={footerfacebook} alt='facebook' />
                  <span className='sr-only'>Facebook</span>
                </a>
              </li>

              <li>
                <a
                  href='https://www.instagram.com/lexa_lawyer/'
                  rel='noreferrer'
                  target='_blank'
                  className='text-white transition hover:opacity-75'
                >
                  <span className='sr-only'>Instagram</span>
                  <img src={footerInstagram} alt='insta' />
                </a>
              </li>

              <li>
                <a
                  href='https://www.linkedin.com/company/lexa_lawyer/'
                  rel='noreferrer'
                  target='_blank'
                  className='text-white transition hover:opacity-75'
                >
                  <span className='sr-only'>LinkedIn</span>
                  <img src={footerLinkedIn} alt='linkedin' />
                </a>
              </li>
              <li>
                <a
                  href='https://www.linkedin.com/company/lexa_lawyer/'
                  rel='noreferrer'
                  target='_blank'
                  className='text-white transition hover:opacity-75'
                >
                  <span className='sr-only'>twitter</span>
                  <img src={footerTwitter} alt='footer_twitter' />
                </a>
              </li>
            </ul>
          </div>
          <div className='grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-2'>
            {/* <div>
                            <p className="font-medium text-white">Quick Links</p>
                            <ul className="mt-6 space-y-4 text-sm pl-0">
                                <li><Link className="text-white transition hover:opacity-75" to='/'>Home</Link>
                                </li>
                                <li><Link className="text-white transition hover:opacity-75" to='/'>About</Link>
                                </li>
                                <li><Link className="text-white transition hover:opacity-75" to='/'>Services</Link>
                                </li>
                                <li><Link className="text-white transition hover:opacity-75" to='/'>Features</Link>
                                </li>
                                <li><Link className="text-white transition hover:opacity-75" to='/'>Contact</Link>
                                </li>
                            </ul>
                        </div> */}
            <div>
              <p className='font-medium text-white'>Quick Links</p>
              <ul className='mt-6 space-y-4 text-sm pl-0'>
                <li>
                  <Link
                    className='text-white transition hover:opacity-75'
                    to='/Refund-Policy'
                  >
                    Refund Policy
                  </Link>
                </li>
                <li>
                  <Link
                    className='text-white transition hover:opacity-75'
                    to='/terms-and-conditions'
                  >
                    Terms and conditions
                  </Link>
                </li>
                <li>
                  <Link
                    className='text-white transition hover:opacity-75'
                    to='/Privacy-Policy'
                  >
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
            {displayContactInfo ? (
              <div>
                <p className='font-medium text-white'>Contact Us</p>
                <ul className='mt-6 space-y-4 text-sm pl-0'>
                  <li className='flex items-center'>
                    <img
                      src={countryFlag}
                      alt='countryFlag'
                      className='w-5 h-5 mr-2'
                    />
                    <a
                      href='tel:+923352232931'
                      className='text-white transition hover:opacity-75'
                    >
                      +92-335-2232931
                    </a>
                  </li>
                  <li className='flex items-center text-white transition hover:opacity-75'>
                    <LocationOnOutlinedIcon className='w-5 h-5 mr-2' />
                    A2053, metroville 3rd, near Memon Masjid, Scheme 33,
                    gulzar-e-hijri, Karachi, Pakistan
                  </li>
                  <li className='flex items-center'>
                    <AlternateEmailOutlinedIcon className='w-5 h-5 mr-2' />
                    <Link
                      href='mailto:contactus@lexa.lawyer'
                      className='text-white transition hover:opacity-75'
                    >
                      contactus@lexa.lawyer
                    </Link>
                  </li>
                </ul>
              </div>
            ) : (
              <div>
                <p className='font-medium text-white'>{sectionTitle}</p>
                <ul className='mt-6 space-y-4 text-sm pl-0'>
                  {blogsToDisplay.map((blog) => (
                    <li key={blog.id}>
                      <Link
                        className='text-white transition hover:opacity-75'
                        to={`/blogs-view/${blog.slug || blog.end_url}`}
                      >
                        {blog.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div>
              <div className='imgs mt-6 text-sm pl-0'>
                <img src={footerJazzcash} alt='jazzcash' />
                <img src={footerEasypaisa} alt='easypaisa' />
                <img src={footerBankTransfer} alt='footerBankTransfer' />
              </div>
            </div>
          </div>
        </div>
        <p className='text-xs text-gray-500 text-center'>
          &copy; 2024. Kuzzee Solution. All rights reserved.
        </p>
      </div>
    </footer>
  )
}

export default BlogsFooter

import React from 'react'
import {
  clientDenning,
  clientLawStudentConcile,
  clientBehriaUni,
  clientUCP,
  clientJoa,
  clientkarachiBar,
  clientSindhBar,
} from '../../assets/landingPageImgs'
import { HeaderSections, MarqueeWrapper } from '../shared/landingPageComponent'

const Mission = () => {
  const clients = [
    { src: clientDenning, alt: 'Client 1' },
    { src: clientBehriaUni, alt: 'Client 2' },
    { src: clientLawStudentConcile, alt: 'Client 3' },
    { src: clientUCP, alt: 'Client 4' },
    { src: clientJoa, alt: 'Client 5' },
    { src: clientkarachiBar, alt: 'Client 6' },
    { src: clientSindhBar, alt: 'Client 7' },
  ]

  return (
    <div className='custom-background py-16 text-center' id='Vision'>
      <div className='container mx-auto px-4 text-center'>
        <HeaderSections
          title='Our Mission'
          descriptionDetails="Our mission at Lexa is to revolutionize legal assistance in Pakistanthrough cutting-edge AI technology. We empower lawyers and law students with direct access to a comprehensive suite of legal resources, facilitating simplified research and study that's available anytime, anywhere. By harnessing AI's capabilities, we aim to enhance efficiency, accuracy, and accessibility in legal practices, ultimately reshaping the landscape of legal education and consultation in Pakistan."
        />
      </div>
      <div className=' px-4 text-center'>
        <h2 className='xs:text-[32px] md:text-5xl font-bold mb-2 mt-36'>
          Our Corporate Clients
        </h2>
        <div className='divider border-2 border-[#0e877d] block m-auto w-20 mb-5'></div>
        {/* MarqueeWrapper for Client Logos */}
        <MarqueeWrapper
          items={clients}
          renderItem={(client, index) => (
            <div key={index} className='flex justify-center items-center px-12'>
              <img
                src={client.src}
                alt={client.alt}
                className='w-40 h-auto mb-4'
              />
            </div>
          )}
          speed={50}
          pauseOnHover
        />
      </div>
    </div>
  )
}

export default Mission

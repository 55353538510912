import React from 'react'
import { Link } from 'react-router-dom'
import { LexaLogoHomeIcon } from '../assets/icons'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined'

const PrivacyPolicy = () => {
  return (
    <>
      <style>
        {`
                .container {
                    max-width: 1200px;
                    margin: auto;
                    padding: 24px;
                }
                .card {
                    max-width: 48rem;
                    margin: auto;
                    background-color: white;
                    padding: 24px;
                    border-radius: 8px;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                }
                .back-link-wrapper {
                    margin-top: 16px;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    
                }
                .page-title {
                    text-align: center;
                    font-size: 24px;
                    font-weight: bold;
                    margin-bottom: 24px;
                }
                section {
                    padding-bottom: 16px;
                    border-bottom: 1px solid #e5e7eb;
                    margin-bottom: 16px;
                }
                h2, h5 {
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 8px;
                }
                p {
                    color: #4b5563;
                    line-height: 1.6;
                }
                ul {
                    list-style-type: disc;
                    padding-left: 20px;
                    margin-top: 8px;
                }
                li {
                    color: #4b5563;
                    margin-bottom: 8px;
                }
                a {
                    text-decoration: none;
                    color: black;
                }
                `}
      </style>

      <div className='container'>
        <Link to='/'>
          <span className='flex justify-center mb-2'>
            <LexaLogoHomeIcon />
          </span>
        </Link>
        <div className='card'>
          <div className='back-link-wrapper'>
            <ArrowCircleLeftOutlinedIcon />
            <Link to='/'>Go back</Link>
          </div>
          <h1 className='page-title'>Privacy Policy - Lexa</h1>

          <section>
            <h5>February 7th, 2024</h5>
            <p>
              Welcome to Lexa! This Privacy Policy outlines how Lexa collects,
              uses, and safeguards your personal information as you access legal
              information and research resources through our platform. By using
              Lexa, you agree to the terms outlined in this Privacy Policy.
            </p>
            <p>
              This Privacy Policy explains what Personal Information (defined
              below) we collect from or about Users, how we use and share that
              information, and your choices concerning our data practices.{' '}
            </p>
          </section>

          <section>
            <h2>1. Information We Collect:</h2>
            <h2>User Provided Information:</h2>

            <p>
              1.1 Lexa and includes information you provide during these
              interactions, such as name, email address, phone number, physical
              address or zip code, demographic information (such as your school,
              gender, age or birthdate), and information about your interests
              and preferences and professional credentials if applicable.
            </p>
            <p>
              1.2 We may combine the information we collect from you through
              Services with other Personal Information we have about you as
              described in this Chatbot Privacy Policy. Any information combined
              with Personal Information will be treated as Personal Information.
            </p>
          </section>

          <section>
            <h2>2. Research Data:</h2>
            <p>
              2.1 Lexa may collect and store information related to your legal
              queries, search history, and interactions with the research
              platform for the purpose of improving our services and enhancing
              your user experience.
            </p>
            <p>
              2.2 The Usage Information we collect include details of your
              online interaction with the Services. Usage Information is
              generally non-identifying, but if we associate it with you as a
              specific and identifiable person, we treat it as Personal
              Information.
            </p>
          </section>

          <section>
            <h2>3. Usage Information:</h2>
            <p>
              3.1 Lexa may collect data on your usage patterns, device
              information, IP address, and the date and time of your
              interactions to optimize platform performance and security.
            </p>
            <p>
              3.2 We may use information about you, including Personal
              Information, to: (1) allow you to participate in the Services,
              including, without limitation, to respond to your questions,
              complaints or comments; (2) tailor content, recommendations and
              offers we display to you on the Services; (3) provide you with
              information, products, services, or suggestions you request; (4)
              improve the Services and our products, and for internal business
              purposes, including the improvement of our AI chat algorithms; (5)
              contact you with regard to your use of the Services and, in our
              discretion, changes to our policies; and (6) as described in the
              Chatbot Privacy Policy or as disclosed at the time you provide
              your information
            </p>
          </section>

          <section>
            <h2>4. How We Use Your Information:</h2>
            <ul>
              <li>
                Lexa uses the information provided to conduct legal research and
                analysis, delivering relevant and accurate content tailored to
                your queries.
              </li>
              <li>
                We may use your contact information to communicate important
                updates, legal research insights, and respond to your inquiries
                or feedback. 4.2 We may use your contact information to
                communicate important updates, legal research insights, and
                respond to your inquiries or feedback.
              </li>
              <li>
                We may disclose information if required to comply with legal
                obligations, protect our rights, or respond to legal processes.
              </li>
              <li>
                Lexa does not entertain queries/questions related to
                self-identity “I” questions, any kind of illegal/illicit queries
                and does not bear liability for credibility of information
                provided, professionals shall analyze and use accordingly.
              </li>
            </ul>
          </section>

          <section>
            <h2>5. Data Security:</h2>
            <p>
              Lexa employs industry-standard security measures to protect your
              personal and research data against unauthorized access,
              disclosure, or alteration.
            </p>
            <p>
              We take data security very seriously and enforce commercially
              reasonable technical, physical, and administrative security
              measures to protect the Personal Information submitted to us, both
              during transmission and once we receive it.
            </p>
          </section>

          <section>
            <h2>6. Information Sharing:</h2>
            <p>
              Lexa does not share your personal information with third parties
              for marketing purposes. We may share aggregated and anonymized
              data for research and statistical analysis.
            </p>
            <p>
              We may share your personal information if you request that we
              share your personal information, to our partners and service
              providers, for administrative and legal reasons, business
              transitions and with your consent.
            </p>
            <p>
              We may share your information with selected third parties,
              including: <br />
              Business partners, vendors, suppliers, and subcontractors who
              perform services on our behalf (these companies are authorized to
              use your personal information only as necessary to provide these
              services to us); Analytics and search engine providers that assist
              us in the improvement and optimization of our Website; Credit
              reference agencies for the purpose of assessing your credit score
              where this is a condition of us entering into a contract with you;
              Payment processors for the purpose of fulfilling relevant payment
              transactions.
            </p>
          </section>

          <section>
            <h2>7. Links to Third-Party Sites:</h2>
            <p>
              The Lexa Service may, from time to time, contain links to and from
              third party websites. If you follow a link to any of these
              websites, please note that these websites have their own privacy
              policies and that we do not accept any responsibility or liability
              for their policies. Please check the individual policies before
              you submit any information to those websites.
            </p>
          </section>

          <section>
            <h2>8. Your Rights:</h2>
            <p>
              In this Section, we have summarized the rights that you possess.
              Your principal rights are:
            </p>
            <ul>
              <li>The right to access</li>
              <li>The right to rectification</li>
              <li>The right to erasure</li>
              <li>The right to restrict processing</li>
              <li>The right to object to processing</li>
              <li>The right to withdraw consent</li>
            </ul>
          </section>

          <section>
            <h2>9. Consent:</h2>
            <p>
              By using Lexa, you consent to the collection, use, and disclosure
              of information as described in this Privacy Policy. If you do not
              agree to this policy, please do not use our Site. Your continued
              use of the Site following the posting of changes to this policy
              will be deemed your acceptance of those changes.
            </p>
          </section>

          <section>
            <h2>10. Changes to Privacy Policy:</h2>
            <p>
              Lexa reserves the right to update this Privacy Policy
              periodically. Users will be notified of significant changes
              through the platform or other communication channels.
            </p>
            <p>
              If you are a copyright owner, or authorized on behalf of one, and
              you believe that the copyrighted work has been copied in a way
              that constitutes copyright infringement, please submit your claim
              via email to contactus@lexa.lawyer, with the subject line:
              “Copyright Infringement” and include in your claim a detailed
              description of the alleged Infringement.
            </p>
          </section>

          <section>
            <h2>11. Contact Us:</h2>
            <p>
              If you have any questions, concerns, or requests regarding this
              Privacy Policy, please contact us at admin@lexa.lawyer <br />
              Thank you for choosing Lexa for your legal research needs. We are
              committed to maintaining the confidentiality and security of your
              information throughout your interactions with our platform.
            </p>
          </section>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy
